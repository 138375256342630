import {
    Autocomplete,
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Divider,
    Grid,
    TextField
} from '@mui/material';
import { gridSpacing } from 'store/constant';
import MainCard from 'ui-component/cards/MainCard';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useState } from 'react';
import { Row } from './components/Row';
import { useApi } from 'Service/axios';
import { useDemandsContext } from 'context/DemandsContext';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { PreviewPlanningModal } from './components/PreviewPlanningModal';
import { InsertOccurencesModal } from './components/InsertOccurencesModal';

// Função auxiliar para reordenar os itens
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
};

export function MachineScreen() {
    const api = useApi();
    const [selectedMachine, setSelectedMachine] = useState(null);
    const [allMachines, setAllMachines] = useState([]);
    const [openConfirmDeleteModal, setOpenConfirmDeleteModal] = useState(false);
    const [openConfirmEncerrarModal, setOpenConfirmEncerrarModal] = useState(false);
    const [selectedRow, setSelectedRow] = useState(null);
    const [openReprogramDialog, setOpenReprogramDialog] = useState(false);
    const [loading, setLoading] = useState(false);

    async function getAllMachines() {
        try {
            const { data } = await api.getLocalMachines();

            setAllMachines(data);
        } catch (error) {
            console.log('🚀 ~ getAllMachines ~ error:', error);
        }
    }

    useEffect(() => {
        // getDemands();
        getAllMachines();
    }, []);

    const [items2, setItems2] = useState([]);

    const onDragEnd = (result) => {
        const { source, destination } = result;

        // Se não há destino (solta o item fora da área dropável)
        if (!destination) {
            return;
        }

        // Reordena os itens na mesma lista
        if (source.index !== destination.index) {
            const reorderedItems = reorder(items2, source.index, destination.index);

            setItems2(reorderedItems);

            handlePreviewPlanning(reorderedItems);
        }
    };

    async function createMachineDemandFunc() {
        const toastId = toast.loading('Programando demandas...');
        if (!selectedMachine) {
            return toast.update(toastId, {
                render: 'Selecione uma maquina!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }

        const payload = items2.map((item, index) => {
            if (item?.ord_in_codigo) {
                return {
                    ord_in_codigo: item.ord_in_codigo,
                    maquinaId: selectedMachine?.id,
                    prioridade: index
                };
            } else {
                if (item?.machineData?.demanda_programada_id) {
                    return {
                        id: item.machineData ? item.machineData.idOcorrencia : item.idOcorrencia ? item.idOcorrencia : item.id,
                        tempo: item.tempo_total_fab,
                        maquinaId: selectedMachine?.id,
                        prioridade: index,
                        programacao: item?.machineData?.demanda_programada_id
                    };
                } else {
                    return {
                        id: item.machineData ? item.machineData.idOcorrencia : item.idOcorrencia ? item.idOcorrencia : item.id,
                        tempo: item.tempo_total_fab,
                        maquinaId: selectedMachine?.id,
                        prioridade: index
                    };
                }
            }
        });

        try {
            await api.createMachineDemand(payload);
            toast.update(toastId, {
                render: 'Demandas atualizadas com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            getDemandsInProgressFunc();
        } catch (error) {
            console.log('🚀 ~ createMachineDemandFunc ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao programar demandas!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function handleDeleteDemand(id) {
        const toastId = toast.loading('Deleting demands...');
        try {
            await api.deleteDemands(id);
            toast.update(toastId, {
                render: 'Demands deleted!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            getDemandsInProgressFunc();
            setOpenConfirmDeleteModal(false);
        } catch (error) {
            console.log('🚀 ~ handleDeleteDemand ~ error:', error);
            toast.update(toastId, {
                render: 'Error deleting demands!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function getDemandsInProgressFunc() {
        setItems2([]);
        const toastId = toast.loading('Carregando demandas...');
        try {
            const { data } = await api.getDemandsInProgress(selectedMachine?.id);

            const row = data.map((item) => ({
                ...item,
                cliente: item.demanda_programada.AGN_ST_FANTASIA,
                agrupamentos: item.demanda_programada.ORD_ST_ALTERNATIVO,
                ordem: item.demanda_programada.ORD_IN_CODIGO,
                diametro: item.demanda_programada.DIAMETRO_GERAL,
                construcao: item.demanda_programada.NORMA_DUTOS,
                bitola: item.demanda_programada.BITOLA,
                id: item.demanda_programada.id,
                status: item.status ? item.status : null,
                tempo: item.tempo_total_fab,
                idOcorrencia: item.ocorrencia_id,
                idParaDelete: item.id
            }));
            handlePreviewPlanning(row);

            if (data.length > 0) {
                toast.update(toastId, {
                    render: 'Demandas carregadas com sucesso!',
                    type: 'success',
                    isLoading: false,
                    autoClose: 3000
                });
            } else {
                toast.update(toastId, {
                    render: 'Maquina sem demandas programadas!',
                    type: 'warning',
                    isLoading: false,
                    autoClose: 3000
                });
            }
        } catch (error) {
            console.log('🚀 ~ getDemandsInProgressFunc ~ error:', error);
            setSelectedMachine(null);
        }
    }

    async function handlePreviewPlanning(machineData) {
        console.log('🚀 ~ handlePreviewPlanning ~ machineData:', machineData);
        const payload = {
            demandas: machineData.map((item, index) => {
                return item?.ord_in_codigo
                    ? {
                          ord_in_codigo: item.ord_in_codigo,
                          maquinaId: selectedMachine?.id,
                          prioridade: index
                      }
                    : {
                          id: item.machineData ? item.machineData.idOcorrencia : item.idOcorrencia,
                          tempo: item.tempo ? item.tempo : item.tempo_total_fab,
                          maquinaId: selectedMachine?.id,
                          prioridade: index
                      };
            })
        };

        try {
            const { data } = await api.prevDemands(payload);

            console.log('🚀 ~ handlePreviewPlanning ~ data:', data);
            const row = data.map((item, index) => ({
                ...item,
                id: index,

                machineData: machineData[index]?.machineData ? machineData[index].machineData : machineData[index] // Validação para evitar duplicação
            }));
            console.log('🚀 ~ row ~ row:', row);

            setItems2(row);
        } catch (error) {
            console.log('🚀 ~ handlePreviewPlanning ~ error:', error);
        }
    }

    useEffect(() => {
        if (selectedMachine) {
            getDemandsInProgressFunc();
        }
    }, [selectedMachine]);

    async function handleUpdateOccurrenceById(id) {
        const toastId = toast.loading('Deletando ocorrencia...');
        console.log('🚀 ~ handleUpdateOccurrenceById ~ id:', selectedRow);
        try {
            await api.updateOccurrence(selectedRow?.machineData?.idParaDelete);
            toast.update(toastId, {
                render: 'Ocorrencia deletada com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            setOpenConfirmEncerrarModal(false);
            getDemandsInProgressFunc();
        } catch (error) {
            console.log('🚀 ~ handleUpdateOccurrenceById ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao atualizar ocorrencia!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function hadleDeleteOcorrencia(id) {
        const toastId = toast.loading('Encerrando demandas...');

        try {
            await api.deletarOcorrencia(id);
            toast.update(toastId, {
                render: 'Demandas encerradas!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });
            getDemandsInProgressFunc();
            setOpenConfirmDeleteModal(false);
        } catch (error) {
            console.log('🚀 ~ hadleDeleteOcorrencia ~ error:', error);
            toast.update(toastId, {
                render: 'Error encerrando demandas!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    async function handleImport() {
        try {
            const response = await api.exportOpenedDemands(selectedMachine?.id);
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            const a = document.createElement('a');
            a.href = url;
            a.download = 'planilha.xlsx';
            document.body.appendChild(a);
            a.click();
            a.remove();

            URL.revokeObjectURL(url);
        } catch (error) {
            console.log('🚀 ~ handleImport ~ error:', error);
        }
    }

    async function reprogramarDemandas() {
        try {
            setLoading(true);
            await api.updateDemandaHoraAtual(selectedMachine?.id);
            toast.success('Demandas reprogramadas com sucesso!');
            setOpenReprogramDialog(false);
            getDemandsInProgressFunc();
        } catch (error) {
            console.error('Error reprogramming demands:', error);
            toast.error('Erro ao reprogramar demandas!');
        } finally {
            setLoading(false);
        }
    }

    console.log('selectedRow', selectedRow);

    return (
        <MainCard title="Maquinas" sx={{ position: 'relative' }}>
            <Grid container spacing={gridSpacing} sx={{ minHeight: '400px' }}>
                {selectedMachine && items2.length > 0 && (
                    <Box sx={{ position: 'absolute', top: 15, right: 230, display: 'flex', gap: '10px' }}>
                        <Button variant="contained" onClick={() => setOpenReprogramDialog(true)}>
                            Reprogramar
                        </Button>
                        <Button variant="contained" onClick={getDemandsInProgressFunc}>
                            Cancelar Preview
                        </Button>
                        <InsertOccurencesModal selectedMachine={selectedMachine} items={items2} setItems={setItems2} />
                    </Box>
                )}

                <Autocomplete
                    disablePortal
                    options={allMachines}
                    getOptionLabel={(option) => option.descricao}
                    sx={{ width: '200px', position: 'absolute', top: 10, right: 10 }}
                    onChange={(e, newValue) => {
                        setSelectedMachine(newValue);
                    }}
                    renderInput={(params) => <TextField {...params} label="Selecione uma maquina" />}
                />
                {selectedMachine ? (
                    items2.length > 0 ? (
                        <DragDropContext onDragEnd={onDragEnd}>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    marginTop: '20px',
                                    gap: '20px',
                                    marginLeft: '20px'
                                }}
                            >
                                <div style={{ width: '100%' }}>
                                    <div
                                        style={{
                                            display: 'grid',
                                            gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                                            padding: '0 10px',
                                            alignItems: 'center',
                                            backgroundColor: '#f0f0f0'
                                        }}
                                    >
                                        {/* <p>ID</p> */}
                                        <p>Cliente</p>
                                        <p>Agrupamentos</p>
                                        <p>Quantidade</p>
                                        <p>Ordem</p>
                                        <p>Diâmetro</p>
                                        <p>Construção</p>
                                        <p>Bitola</p>
                                        <p>Tempo Total</p>
                                        <p>Necessidade</p>
                                        {/* <p>Início</p> */}
                                        <p>Previsão</p>
                                        <p>Status</p>
                                        <p>Ações</p>
                                    </div>
                                    <Droppable droppableId="droppable2">
                                        {(provided) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.droppableProps}
                                                style={{
                                                    padding: '10px',
                                                    minHeight: '300px',
                                                    backgroundColor: '#f0f0f0',
                                                    maxHeight: 'calc(100vh - 380px)',
                                                    overflow: 'auto'
                                                }}
                                            >
                                                {items2.map((item, index) => (
                                                    <Draggable key={item.id} draggableId={item?.id?.toString()} index={index}>
                                                        {(provided, snapshot) => (
                                                            <div
                                                                ref={provided.innerRef}
                                                                {...provided.draggableProps}
                                                                {...provided.dragHandleProps}
                                                                style={{
                                                                    backgroundColor: snapshot.isDragging ? '#d3d3d3' : '#ffffff',
                                                                    border: '1px solid #ccc',
                                                                    ...provided.draggableProps.style
                                                                }}
                                                            >
                                                                <Row
                                                                    data={item}
                                                                    setOpenConfirmDeleteModal={setOpenConfirmDeleteModal}
                                                                    setOpenConfirmEncerrarModal={setOpenConfirmEncerrarModal}
                                                                    setSelectedRow={setSelectedRow}
                                                                />
                                                            </div>
                                                        )}
                                                    </Draggable>
                                                ))}
                                                {provided.placeholder}
                                            </div>
                                        )}
                                    </Droppable>
                                </div>
                            </div>
                        </DragDropContext>
                    ) : (
                        <div style={{ width: '100%', marginTop: '30px' }}>
                            <h3 style={{ textAlign: 'center' }}>Nenhum item programado!</h3>
                        </div>
                    )
                ) : (
                    <div style={{ width: '100%', marginTop: '30px' }}>
                        <h3 style={{ textAlign: 'center' }}>Selecione uma máquina!</h3>
                    </div>
                )}
                {selectedMachine && items2.length > 0 && (
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px', width: '100%', padding: '10px' }}>
                        <Button variant="contained" onClick={handleImport}>
                            Exportar planilha
                        </Button>
                        <Button variant="contained" onClick={createMachineDemandFunc}>
                            Atualizar maquina
                        </Button>
                    </div>
                )}
                <Dialog
                    open={openConfirmDeleteModal}
                    onClose={() => setOpenConfirmDeleteModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" variant="h3">
                        {'Deseja mesmo deletar esta programação?'}
                    </DialogTitle>
                    <Divider />
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={() => setOpenConfirmDeleteModal(false)}>
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (selectedRow.ord_in_codigo) {
                                    handleDeleteDemand(selectedRow?.ord_in_codigo);
                                } else {
                                    hadleDeleteOcorrencia(selectedRow?.machineData?.id);
                                }
                            }}
                        >
                            Deletar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog
                    open={openConfirmEncerrarModal}
                    onClose={() => setOpenConfirmEncerrarModal(false)}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title" variant="h3">
                        {'Deseja mesmo encerrar esta ocorrência?'}
                    </DialogTitle>
                    <Divider />
                    <DialogActions>
                        <Button variant="contained" color="error" onClick={() => setOpenConfirmEncerrarModal(false)}>
                            Cancelar
                        </Button>
                        <Button
                            variant="contained"
                            onClick={() => {
                                if (!selectedRow.ord_in_codigo && selectedRow?.machineData?.id) {
                                    handleUpdateOccurrenceById(selectedRow?.id);
                                }
                            }}
                        >
                            Encerrar
                        </Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openReprogramDialog} onClose={() => setOpenReprogramDialog(false)}>
                    <DialogTitle
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold'
                        }}
                    >
                        Reprogramar Demandas
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText
                            sx={{
                                color: 'red'
                            }}
                        >
                            A reprogramação das demandas será feita com base no horário atual. Deseja continuar?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpenReprogramDialog(false)} variant="contained" color="error">
                            Cancelar
                        </Button>
                        <Button variant="contained" color="primary" onClick={reprogramarDemandas} disabled={loading}>
                            {loading ? <CircularProgress size={24} color="inherit" /> : 'Confirmar'}
                        </Button>
                    </DialogActions>
                </Dialog>
            </Grid>
        </MainCard>
    );
}
