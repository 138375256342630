import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, TextField } from '@mui/material';
import { useState } from 'react';
import { useApi } from 'Service/axios';
import { toast } from 'react-toastify';
import { useEffect } from 'react';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 2
};

export function CreateEditOccurrences({ open, setOpen, get, selectedRow }) {
    const api = useApi();
    const handleClose = () => setOpen(false);
    const [name, setName] = useState('');

    useEffect(() => {
        if (selectedRow) {
            setName(selectedRow.nome);
        }
    }, [selectedRow, open]);

    async function handleCreate() {
        const toastId = toast.loading('Criando ocorrência...');
        try {
            await api.createOccurrence({ nome: name });
            toast.update(toastId, { render: 'Ocorrência criada com sucesso!', type: 'success', isLoading: false, autoClose: 2000 });
            setOpen(false);
            get();
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao criar ocorrência!', type: 'error', isLoading: false, autoClose: 2000 });
            console.log('🚀 ~ handleCreate ~ error:', error);
        }
    }

    async function handleEdit() {
        const toastId = toast.loading('Editando ocorrência...');
        try {
            await api.updateOccurrenceName({ nome: name }, selectedRow.id);
            toast.update(toastId, { render: 'Ocorrência editada com sucesso!', type: 'success', isLoading: false, autoClose: 2000 });
            setOpen(false);
            get();
        } catch (error) {
            toast.update(toastId, { render: 'Erro ao editar ocorrência!', type: 'error', isLoading: false, autoClose: 2000 });
            console.log('🚀 ~ handleEdit ~ error:', error);
        }
    }
    return (
        <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
            <Box sx={style}>
                <h3 style={{ textAlign: 'center' }}>{selectedRow ? 'Editar' : 'Cadastrar'} ocorrência</h3>
                <Divider sx={{ margin: '10px' }} />
                <TextField
                    fullWidth
                    id="outlined-basic"
                    label="Ocorrência"
                    name="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    variant="outlined"
                />
                <Button variant="contained" onClick={selectedRow ? handleEdit : handleCreate} sx={{ marginTop: '10px', width: '100%' }}>
                    {selectedRow ? 'Editar' : 'Criar'}
                </Button>
            </Box>
        </Modal>
    );
}
