import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { IconEdit, IconTrash } from '@tabler/icons';
import { DateAndHour, DateAndHourPreview, formatDateAndTime } from 'utils/DateAndHour';
import { formatacaoData } from 'utils/formatacaoData';
import { formatarTempo } from 'utils/formatValueByHour';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

export function Row({ data, setOpenConfirmDeleteModal, setOpenConfirmEncerrarModal, setSelectedRow }) {
    function calculateStatus(cel_dt_programacao, tempo_previsto_termino) {
        if (data?.tipo === 'ocorrencia') {
            return 'Ocorrência';
        }

        const dateOne = new Date(cel_dt_programacao); // Data programada
        const dateTwo = new Date(tempo_previsto_termino); // Data de término previsto

        // Normaliza ambas as datas para o início do dia (meia-noite)
        dateOne.setUTCHours(0, 0, 0, 0);
        dateTwo.setUTCHours(0, 0, 0, 0);

        // Verifica as comparações entre as datas
        if (dateOne.getTime() === dateTwo.getTime()) {
            return 'Risco de Atraso'; // Ambas as datas são iguais (mesmo dia)
        } else if (dateOne < dateTwo) {
            return 'Atrasado'; // Data programada é anterior à data de término
        } else {
            return 'Em andamento'; // Data programada é posterior à data de término
        }
    }

    const statusRow = calculateStatus(data?.cel_dt_programacao, data?.tempo_previsto_termino);

    const backgroundColor =
        statusRow === 'Atrasado' ? 'red' : statusRow === 'Em andamento' ? 'green' : statusRow === 'Ocorrência' ? 'orange' : '#FFFF00';

    const textColor = backgroundColor === 'green' || backgroundColor === 'red' ? 'white' : 'black';
    console.log('data', data);

    return (
        <Box
            sx={{
                display: 'grid',
                gridTemplateColumns: '2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr',
                padding: '0 10px',
                alignItems: 'center',
                backgroundColor: '#fafafa'
            }}
        >
            <p>{data?.machineData?.cliente ? data?.machineData?.cliente : `OCORRÊNCIA - ${data?.nome?.toUpperCase()}`}</p>
            <p>{data?.machineData?.agrupamentos ? data?.machineData?.agrupamentos : '-'}</p>
            <p>{data?.quantidade_pecas ? data?.quantidade_pecas : '-'}</p>
            <p>{data?.ord_in_codigo ? data?.ord_in_codigo : '-'}</p>
            <p>{data?.diametro_geral ? data?.diametro_geral?.split('.')[0] : '-'}</p>
            <p>{data?.forma_construtiva ? data?.forma_construtiva : '-'}</p>
            <p>{data?.bitola ? data?.bitola : '-'}</p>
            <p>{formatarTempo(data?.tempo_total_fab)}</p>
            <p>{data?.cel_dt_programacao ? formatacaoData(data?.cel_dt_programacao) : '-'}</p>
            {/* <p>{formatDateAndTime(data?.data_inicio)}</p> */}
            <p>{formatDateAndTime(data?.tempo_previsto_termino)}</p>

            <div
                style={{
                    background: backgroundColor,
                    color: textColor, // Ajuste da cor do texto
                    fontWeight: 'bold',
                    height: '100%',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
            >
                {statusRow}
            </div>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '-10px'
                }}
            >
                <Button
                    variant="outlined"
                    color="error"
                    sx={{ width: '40px', marginLeft: '10px' }}
                    disabled={data?.isTemp || data?.machineData?.isTemp}
                    onClick={() => {
                        setOpenConfirmDeleteModal(true);
                        setSelectedRow(data);
                    }}
                >
                    <IconTrash />
                </Button>
                {!data?.machineData?.agrupamentos && (
                    <Button
                        variant="outlined"
                        sx={{ width: '40px', marginLeft: '5px' }}
                        disabled={data?.isTemp || data?.machineData?.isTemp}
                        onClick={() => {
                            setOpenConfirmEncerrarModal(true);
                            setSelectedRow(data);
                        }}
                    >
                        <CheckCircleOutlineIcon />
                    </Button>
                )}
            </Box>
        </Box>
    );
}
