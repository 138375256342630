import { Box, IconButton, Tooltip } from '@mui/material';
import { IconTrash } from '@tabler/icons';

export function Row({ data, setOpenConfirmDeleteModal, setSelectedRow }) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '0 10px',
                backgroundColor: data.status ? '#ccc' : ''
            }}
        >
            {/* <p>{data?.id}</p> */}
            <p
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100px'
                }}
            >
                {data?.cliente}
            </p>
            <p>{data?.agrupamentos}</p>
            <p>{data?.ordem}</p>
            <p>{Number(data?.diametro).toFixed(0)}</p>
            <p
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    maxWidth: '100px'
                }}
            >
                {data?.construcao}
            </p>
            <p style={{ width: '50px' }}>{data?.bitola}</p>

            <IconButton
                disabled={!data?.status}
                onClick={() => {
                    setOpenConfirmDeleteModal(true);
                    setSelectedRow(data);
                }}
            >
                <IconTrash color={!data?.status ? 'gray' : 'red'} />
            </IconButton>
        </Box>
    );
}
