import {
    Box,
    Button,
    Divider,
    Drawer,
    Fab,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip
} from '@mui/material';
import React from 'react';
import * as Yup from 'yup';
import { useApi } from 'Service/axios';
import { useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useEffect } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { gridSpacing } from 'store/constant';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import { useParams } from 'react-router';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';
import ReplyIcon from '@mui/icons-material/Reply';
import { LoadPointerBtn } from 'ui-component/LoadPointerBtn';
import { formatarHorario } from 'utils/FormatarHoraParaPonto';
import { ModalLoadAnimated } from 'ui-component/ModalLoad';
import { format } from 'date-fns';

const AddNewConstruction = ({ currentDistribuition }) => {
    const navigate = useNavigate();
    const { id } = useParams();
    const api = useApi();
    const [codficator, setCodficator] = useState([]);
    const [fields, setFields] = useState([]);
    const [currentWork, setCurrentWork] = useState({});
    const [loadRegister, setLoadRegister] = useState(false);
    const [loadPage, setLoadPage] = useState(false);
    const optionsSelect = [
        { id: 1, name: 'PCP' },
        { id: 2, name: 'Codificação' },
        { id: 3, name: 'Expedição' }
    ];

    const getOne = async () => {
        setLoadPage(true);
        try {
            const { data } = await api.getOpneDistrutionWorks(id);

            setCurrentWork(data);
            setLoadPage(true);
        } catch (error) {
            console.log('🚀 ~ file: AddNewConstruction.js:48 ~ getOne ~ error:', error);
        }
    };

    const getEncoders = async () => {
        try {
            const { data } = await api.getAllUsers();
            const filteredCodificator = data.filter((item) => item.role === 'Codificador' || item.role === 'Comercial/Engenharia');
            setCodficator(filteredCodificator);
        } catch (error) {
            console.log('🚀 ~ file: index.js:62 ~ getEncoders ~ error:', error.message);
        }
    };

    useEffect(() => {
        getEncoders();
        getOne();
    }, [id]);

    const formatDate = (date) => {
        const localDate = new Date(date);
        localDate.setMinutes(localDate.getMinutes() + localDate.getTimezoneOffset());
        const formattedDate = localDate.toISOString().split('T')[0];
        return formattedDate;
    };

    const initialFields = {
        inhold: '',
        descriptionCoding: '',
        codficator: '',
        Prohibited: currentWork?.dt_registro ? formatDate(new Date(currentWork?.dt_registro)) : null,
        delivery: currentWork?.dt_entrega ? formatDate(new Date(currentWork?.dt_entrega)) : null,
        codingStart: null,
        codingDelivery: null,
        programming: null,
        cut: null,
        manufacture: null,
        sawmill: null,
        painting: null,
        expedition: null,
        pcpObs: '',
        expeditionObs: '',
        codingObs: '',
        type: null,
        hours: null,
        dificultLevels: null,
        serralheria: null,
        isolamento: null,
        solda: null,
        pinturaEnvio: null,
        pinturaRetorno: null,
        suportePintura: null,
        finalizacao: null,
        reprogramming: null,
        paintType: null,
        packing: null
    };

    const updateFields = (fieldIndex, campo, value) => {
        setFields((prevCampos) => {
            const newFields = [...prevCampos];
            newFields[fieldIndex][campo] = value;
            return newFields;
        });
    };

    const removerCampoEspecifico = (index) => {
        setFields((prevCampos) => {
            const novosCampos = [...prevCampos];
            novosCampos.splice(index, 1);
            return novosCampos;
        });
    };

    const handleRegister = async () => {
        setLoadRegister(true);

        const payloads = fields.map((field) => {
            const descriptionWorks = [];

            if (field.pcpObs && field.pcpObs.trim() !== '') {
                descriptionWorks.push({
                    description: field.pcpObs,
                    type: 'pcp'
                });
            }

            if (field.expeditionObs && field.expeditionObs.trim() !== '') {
                descriptionWorks.push({
                    description: field.expeditionObs,
                    type: 'expedition'
                });
            }

            if (field.codingObs && field.codingObs.trim() !== '') {
                descriptionWorks.push({
                    description: field.codingObs,
                    type: 'coding'
                });
            }

            if (field.codficator === '') {
                toast.error('Verifique se selecionou um codificador');

                return;
            }

            if (field.hours === null) {
                toast.error('Verifique se inseriu uma hora estimada');
                return;
            }

            if (field.dificultLevels === null) {
                toast.error('Verifique se selecionou um nível');
                return;
            }

            if (field.packing === null) {
                toast.error('Verifique se selecionou um tipo de embalagem');
                return;
            }

            if (field.paintType === null) {
                toast.error('Verifique se selecionou um tipo de pintura');
                return;
            }

            return {
                gepro: currentWork.gepro,
                client: currentWork.client,
                obra: currentWork.obra,
                in_hold: false,
                pcp_enter: field.Prohibited || null,
                pcp_exit: field.delivery || null,
                codification_end: field.codingDelivery || null,
                codification_start: field.codingStart || null,
                vulcan_prog: field.programming || null,
                vulcan_cut: field.cut || null,
                production_fab: field.manufacture || null,
                production_spiro: field.sawmill || null,
                production_painting: field.painting || null,
                production_leaving: field.expedition || null,
                level: field.dificultLevels || null,
                estimated_hours: formatarHorario(field.hours) || null,
                production_serralheria: field.serralheria || null,
                production_isolamento: field.isolamento || null,
                production_solda: field.solda || null,
                paint_envio: field.pinturaEnvio || null,
                paint_retorno: field.pinturaRetorno || null,
                finalizacao: field.finalizacao || null,
                description: field.descriptionCoding || null,
                codificatorsId: field.codficator || null,
                DescriptionWorks: descriptionWorks.length > 0 ? descriptionWorks : [],
                paint_type: field.paintType || null,
                packing: field.packing || null,
                reprogramming: field.reprogramming || null
            };
        });

        try {
            const responses = await Promise.all(payloads.map((payload) => api.createDistributionWorks(payload)));

            // Verifica se responses não está vazio
            if (responses.length > 0) {
                for (const response of responses) {
                    if (response.data && response.data.id) {
                        await api.createdCofidification(response.data.id);
                    } else {
                        console.error('response.data.id is missing:', response.data);
                    }
                }

                navigate(-1);
                toast.success('Obras distribuídas com sucesso!');
                setLoadRegister(false);
            } else {
                console.error('No responses received from server.');
                toast.error('Nenhuma resposta recebida do servidor.');
                setLoadRegister(false);
            }
        } catch (error) {
            setLoadRegister(false);
            console.log(error);
        }
    };

    const dificultLevels = [
        { id: 1, level: 'Nível 1' },
        { id: 2, level: 'Nível 2' },
        { id: 3, level: 'Nível 3' },
        { id: 4, level: 'Nível 4' }
    ];

    const packingTypesList = [
        'N/A',
        'PAPELÃO',
        'ENGRADADO + PAPELÃO',
        'ENGRADADO',
        'ENGRADADO + FILME STRECH',
        'ENGRADADO + FILME TERMORETRATIL',
        'PALLET',
        'PALLET + FILME TERMORETRATIL',
        'PALLET + FILME STRECH',
        'TAMPONAMENTO'
    ];

    const paintTypesList = [
        'N/A',
        'ALUMINIO ALTA TEMPERATURA',
        'ELÉTROSTATICA (AGUARDANDO DEFINIÇÃO DO RAL)',
        'FUNDO GALVITE',
        'MUNSELL 2,5 (VERIFICAR O ACABAMENTO)',
        'MUNSELL 5GY 6/1 SEMI BRILHO (CINZA)',
        'MUNSELL 5R4/14 BRILHANTE',
        'MUNSELL N3,5 FOSCO',
        'MUNSELL N6,5 BRILHANTE',
        'MUNSELL N6.5 SEMI BRILHO',
        'MUNSELL N8 SEMI BRILHO',
        'MUNSELL N9,5 SEMI BRILHO',
        'OXIDO DE FERRO',
        'RAL 1003 SEMI BRILHO',
        'RAL 1013 FOSCO',
        'RAL 1015 BRILHANTE',
        'RAL 1016 FOSCO',
        'RAL 5015 BRILHANTE',
        'RAL 5015 FOSCO',
        'RAL 5017 SEMI BRILHO',
        'RAL 7010 SEMI BRILHO',
        'RAL 7024 FOSCO',
        'RAL 7039 SEMI BRILHO',
        'RAL 9001 SEMI BRILHO',
        'RAL 9001 SEMI BRILHO',
        'RAL 9002 FOSCO',
        'RAL 9003 BRILHANTE',
        'RAL 9003 FOSCO',
        'RAL 9003 SEMI BRILHO',
        'RAL 9005 BRILHANTE',
        'RAL 9005 FOSCO',
        'RAL 9005 SEMI BRILHO',
        'RAL 9007 SEMI BRILHO',
        'RAL 9010 FOSCO',
        'RAL 9010 SEMI BRILHO',
        'RAL 9011 SEMI BRILHO',
        'RAL 9016 SEMI BRILHO',
        'RETOQUE ALUMINIO',
        'SUVINIL OVELHA (AMOSTRA)'
    ];

    return (
        <MainCard title={'Nova - Distribuição de obras'}>
            <Grid container spacing={gridSpacing} sx={{ padding: '20px', position: 'relative' }}>
                {loadRegister && <ModalLoadAnimated open={loadRegister} />}

                <Fab variant="extended" sx={{ position: 'fixed', bottom: '50px' }} onClick={() => navigate(-1)}>
                    <ReplyIcon sx={{ mr: 1 }} />
                    Voltar
                </Fab>
                <form style={{ display: 'flex', flexDirection: 'column', gap: '10px', width: '950px', margin: '0 auto' }}>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                        <FormControl fullWidth>
                            <TextField
                                label="Cliente"
                                name="costumer"
                                disabled
                                value={currentWork.client}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField
                                label="Obra"
                                name="construction"
                                disabled
                                value={currentWork.obra}
                                InputLabelProps={{ shrink: true }}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <TextField label="Gepro" name="gepro" disabled value={currentWork.gepro} InputLabelProps={{ shrink: true }} />
                        </FormControl>
                    </div>
                    {fields.map((item, index) => (
                        <>
                            <div key={index} style={{ display: 'flex' }}>
                                <FormControl>
                                    <InputLabel id="demo-multiple-name-label">Codificador</InputLabel>
                                    <Select
                                        sx={{ width: '300px', marginRight: '20px' }}
                                        label="Codificador"
                                        labelId="demo-multiple-name-label"
                                        id="demo-multiple-name"
                                        type="select"
                                        name="codficator"
                                        value={item.codficator}
                                        onChange={(e) => updateFields(index, 'codficator', e.target.value)}
                                    >
                                        {codficator.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {item.username}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <TextField
                                        sx={{ width: '300px' }}
                                        label="Descrição"
                                        type="text"
                                        value={item.descriptionCoding}
                                        onChange={(e) => updateFields(index, 'descriptionCoding', e.target.value)}
                                    />
                                </FormControl>

                                <FormControl>
                                    <TextField
                                        sx={{ width: '160px', marginLeft: '15px', marginRight: '15px' }}
                                        label="Horas Estimadas"
                                        type="time"
                                        value={item.hours}
                                        onChange={(e) => updateFields(index, 'hours', e.target.value)}
                                    />
                                </FormControl>

                                <FormControl>
                                    <InputLabel id="demo-nivel">Nível</InputLabel>
                                    <Select
                                        sx={{ width: '150px' }}
                                        label="Nível"
                                        labelId="demo-nivel"
                                        id="demo-nivel"
                                        type="select"
                                        name="dificultLevels"
                                        value={item.dificultLevels}
                                        onChange={(e) => updateFields(index, 'dificultLevels', e.target.value)}
                                    >
                                        {dificultLevels.map((item) => (
                                            <MenuItem key={item.id} value={item.level}>
                                                {item.level}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>
                            <Divider />
                            <span>PCP</span>
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    marginTop: '10px',
                                    gap: '20px'
                                }}
                            >
                                <FormControl>
                                    <TextField
                                        label="Entrada"
                                        type="date"
                                        name="Prohibited"
                                        value={item.Prohibited}
                                        onChange={(e) => updateFields(index, 'Prohibited', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{
                                            width: '220px',
                                            '& input:disabled': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                            }
                                        }}
                                        disabled
                                        variant="filled"
                                    />
                                </FormControl>

                                <FormControl>
                                    <TextField
                                        label="Entrega"
                                        type="date"
                                        name="delivery"
                                        value={item.delivery}
                                        onChange={(e) => updateFields(index, 'delivery', e.target.value)}
                                        InputLabelProps={{ shrink: true }}
                                        sx={{
                                            width: '220px',
                                            '& input:disabled': {
                                                color: 'rgba(0, 0, 0, 0.6)',
                                                fontWeight: 'bold' // Define a cor do texto quando desativado
                                            }
                                        }}
                                        disabled
                                        variant="filled"
                                    />
                                </FormControl>
                                <FormControl sx={{ marginLeft: '10px' }}>
                                    <InputLabel id="Tipo de pintura">Tipo de pintura</InputLabel>
                                    <Select
                                        sx={{ width: '220px' }}
                                        label="Tipo de pintura"
                                        labelId="Tipo de pintura"
                                        id="Tipo de pintura"
                                        type="select"
                                        name="paintType"
                                        value={item.paintType}
                                        onChange={(e) => updateFields(index, 'paintType', e.target.value)}
                                    >
                                        {paintTypesList.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <FormControl>
                                    <InputLabel id="Tipo de embalagem">Tipo de embalagem</InputLabel>
                                    <Select
                                        sx={{ width: '220px' }}
                                        label="Tipo de embalagem"
                                        labelId="Tipo de embalagem"
                                        id="Tipo de embalagem"
                                        type="select"
                                        name="packing"
                                        value={item.packing}
                                        onChange={(e) => updateFields(index, 'packing', e.target.value)}
                                    >
                                        {packingTypesList.map((item, index) => (
                                            <MenuItem key={index} value={item}>
                                                {item}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    width: '100%',
                                    gap: '30px'
                                }}
                            >
                                <div>
                                    <span>Codificação</span>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            gap: '20px',
                                            marginTop: '10px'
                                        }}
                                    >
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Início"
                                                type="date"
                                                name="codingStart"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.codingStart}
                                                onChange={(e) => updateFields(index, 'codingStart', e.target.value)}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Entrega"
                                                type="date"
                                                name="codingDelivery"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.codingDelivery}
                                                onChange={(e) => updateFields(index, 'codingDelivery', e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div>
                                    <span>Vulcan</span>
                                    <div
                                        style={{
                                            display: 'flex',
                                            alignItems: 'center',
                                            width: '100%',
                                            gap: '20px',
                                            marginTop: '10px'
                                        }}
                                    >
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Programação"
                                                type="date"
                                                name="programming"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.programming}
                                                onChange={(e) => updateFields(index, 'programming', e.target.value)}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Corte"
                                                type="date"
                                                name="cut"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.cut}
                                                onChange={(e) => updateFields(index, 'cut', e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
                                    <span>Produção</span>
                                    <span>Setor (TDC/Giro/Esp.)</span>
                                </div>
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        width: '100%',
                                        gap: '20px',
                                        marginTop: '10px',
                                        justifyContent: 'space-between'
                                    }}
                                >
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '10px' }}>
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Finalização de fabricação"
                                                type="date"
                                                name="manufacture"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.manufacture}
                                                onChange={(e) => updateFields(index, 'manufacture', e.target.value)}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Spiro"
                                                type="date"
                                                name="sawmill"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.sawmill}
                                                onChange={(e) => updateFields(index, 'sawmill', e.target.value)}
                                            />
                                        </FormControl>
                                    </div>

                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '10px' }}>
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Isolamento"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                name="isolamento"
                                                value={item.isolamento}
                                                onChange={(e) => updateFields(index, 'isolamento', e.target.value)}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Solda"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.solda}
                                                name="solda"
                                                onChange={(e) => updateFields(index, 'solda', e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                                <div>
                                    <span>Suportes/Flanges</span>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '10px' }}>
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Serralheria"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.serralheria}
                                                name="serralheria"
                                                onChange={(e) => updateFields(index, 'serralheria', e.target.value)}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Pintura"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.painting}
                                                name="painting"
                                                onChange={(e) => updateFields(index, 'painting', e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div>
                                    <span>Pintura eletroestática</span>
                                    <div style={{ display: 'flex', alignItems: 'center', gap: '20px', marginTop: '10px' }}>
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Envio"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.pinturaEnvio}
                                                name="pinturaEnvio"
                                                onChange={(e) => updateFields(index, 'pinturaEnvio', e.target.value)}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px' }}
                                                label="Retorno"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.pinturaRetorno}
                                                name="pinturaRetorno"
                                                onChange={(e) => updateFields(index, 'pinturaRetorno', e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                <div>
                                    <span>Pintura Refrin</span>
                                    <div>
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px', marginTop: '10px' }}
                                                label="Finalização"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                name="finalizacao"
                                                onChange={(e) => updateFields(index, 'finalizacao', e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div>
                                    <span>Expedição</span>
                                    <div>
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px', marginTop: '10px' }}
                                                label="Previsão"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.expedition}
                                                name="expedition"
                                                onChange={(e) => updateFields(index, 'expedition', e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                                <div>
                                    <span style={{ marginLeft: '10px' }}>Reprogramação</span>
                                    <div>
                                        <FormControl>
                                            <TextField
                                                sx={{ width: '220px', marginTop: '10px', marginLeft: '10px' }}
                                                label="Reprogramação"
                                                type="date"
                                                InputLabelProps={{ shrink: true }}
                                                value={item.reprogramming}
                                                name="reprogramming"
                                                onChange={(e) => updateFields(index, 'reprogramming', e.target.value)}
                                            />
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <Divider />
                            <div>Observação</div>
                            <div style={{ display: 'flex', width: '500px', gap: '20px' }}>
                                <div>
                                    <FormControl sx={{ width: '100%' }}>
                                        <InputLabel id="selectID">Tipo</InputLabel>
                                        <Select
                                            sx={{ width: '150px' }}
                                            label="Tipo"
                                            labelId="selectID"
                                            name="Tipo"
                                            value={item.type}
                                            onChange={(e) => updateFields(index, 'type', e.target.value)}
                                        >
                                            {optionsSelect.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                                {fields[index]?.type === 1 && (
                                    <TextField
                                        sx={{ width: '400px' }}
                                        label="Observação PCP"
                                        multiline
                                        rows={4}
                                        name="pcpObs"
                                        value={item.pcpObs}
                                        onChange={(e) => updateFields(index, 'pcpObs', e.target.value)}
                                    />
                                )}

                                {fields[index]?.type === 3 && (
                                    <TextField
                                        sx={{ width: '400px' }}
                                        label="Observação expedição"
                                        multiline
                                        rows={4}
                                        name="expeditionObs"
                                        value={item.expeditionObs}
                                        onChange={(e) => updateFields(index, 'expeditionObs', e.target.value)}
                                    />
                                )}

                                {fields[index]?.type === 2 && (
                                    <TextField
                                        sx={{ width: '400px' }}
                                        label="Observação codificação"
                                        multiline
                                        rows={4}
                                        name="codingObs"
                                        value={item.codingObs}
                                        onChange={(e) => updateFields(index, 'codingObs', e.target.value)}
                                    />
                                )}
                            </div>
                            <div
                                style={{
                                    display: 'flex',
                                    width: '100%',
                                    justifyContent: 'end'
                                }}
                            >
                                <Tooltip title="Remover Campo Específico">
                                    <Button variant="contained" color="error" onClick={() => removerCampoEspecifico(index)}>
                                        <DeleteIcon />
                                    </Button>
                                </Tooltip>
                            </div>
                            <Divider />
                        </>
                    ))}
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Button variant="contained" color="warning" onClick={() => setFields((prev) => [...prev, initialFields])}>
                            Adicionar novo codificador
                        </Button>

                        <Button variant="contained" color="primary" onClick={handleRegister} disabled={loadRegister}>
                            Salvar Distribuição
                        </Button>
                    </Box>
                </form>
            </Grid>
        </MainCard>
    );
};

export default AddNewConstruction;
