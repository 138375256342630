import { Box, Button, Grid, IconButton, Tooltip } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { IconEdit, IconTrash } from '@tabler/icons';
import { useState } from 'react';
import MainCard from 'ui-component/cards/MainCard';
import { CreateEditOccurrences } from './components/CreatEditOccurrences.';
import { useApi } from 'Service/axios';
import { useEffect } from 'react';

export function Occurrences() {
    const [openModal, setOpenModal] = useState(false);
    const [row, setRow] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const api = useApi();

    const columns = [
        { field: 'id', headerName: 'ID', flex: 1 },
        { field: 'nome', headerName: 'Nome', flex: 1 },
        {
            field: 'actions',
            headerName: 'Ações',
            flex: 0.3,
            renderCell: (params) => {
                return (
                    <div>
                        <Tooltip title="Editar">
                            <IconButton
                                onClick={() => {
                                    setSelectedRow(params.row);
                                    setOpenModal(true);
                                }}
                            >
                                <IconEdit />
                            </IconButton>
                        </Tooltip>
                        {/* <Tooltip title="Excluir">
                            <IconButton>
                                <IconTrash />
                            </IconButton>
                        </Tooltip> */}
                    </div>
                );
            }
        }
    ];

    async function getOccurrences() {
        try {
            const { data } = await api.getAllOccurrences();
            setRow(data);
        } catch (error) {
            console.log('🚀 ~ getOccurrences ~ error:', error);
        }
    }

    useEffect(() => {
        getOccurrences();
    }, []);

    return (
        <MainCard title="Ocorrências" icon="IconTimelineEventText" sx={{ position: 'relative' }}>
            <CreateEditOccurrences open={openModal} setOpen={setOpenModal} get={getOccurrences} selectedRow={selectedRow} />
            <Grid container spacing={2}>
                <Box sx={{ position: 'absolute', top: 20, right: 20 }}>
                    <Button variant="contained" onClick={() => setOpenModal(true)}>
                        Criar Ocorrência
                    </Button>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <DataGrid rows={row} columns={columns} sx={{ height: 'calc(100vh - 250px)' }} />
                </Box>
            </Grid>
        </MainCard>
    );
}
