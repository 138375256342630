import { Autocomplete, Box, Button, Divider, Modal, TextField } from '@mui/material';

import { useEffect } from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useApi } from 'Service/axios';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 2,
    borderRadius: 2
};

const generateOptions = (start, end) => {
    return Array.from({ length: end - start + 1 }, (_, i) => (start + i).toString().padStart(2, '0'));
};

const convertToSeconds = (hours, minutes) => {
    const totalHours = parseInt(hours, 10) || 0;
    const totalMinutes = parseInt(minutes, 10) || 0;
    return totalHours * 3600 + totalMinutes * 60;
};

export function InsertOccurencesModal({ selectedMachine, items, setItems }) {
    const api = useApi();
    const [open, setOpen] = useState(false);
    const [occurences, setOccurences] = useState([]);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [hours, setHours] = useState(null);
    const [minutes, setMinutes] = useState(null);
    const [occurenceSelected, setOccurenceSelected] = useState(null);

    async function getOccurences() {
        try {
            const { data } = await api.getAllOccurrences();

            setOccurences(data);
        } catch (error) {
            console.log('🚀 ~ functuon ~ error:', error);
        }
    }

    useEffect(() => {
        if (open) getOccurences();
    }, [open]);

    async function createMachineDemandFunc() {
        const toastId = toast.loading('Programando demandas...');

        // const payload = {
        //     demandas: items.map((item, index) => {
        //         return {
        //             ord_in_codigo: item?.ord_in_codigo,
        //             maquinaId: selectedMachine?.id,
        //             prioridade: index
        //         };
        //     })
        // };

        const payload = {
            demandas: items.map((item, index) => {
                return item?.ord_in_codigo
                    ? {
                          ord_in_codigo: item.ord_in_codigo,
                          maquinaId: selectedMachine?.id,
                          prioridade: index
                      }
                    : {
                          id: item.machineData ? item.machineData.idOcorrencia : item.idOcorrencia ? item.idOcorrencia : item.id,
                          tempo: item.tempo ? item.tempo : item.tempo_total_fab,
                          maquinaId: selectedMachine?.id,
                          prioridade: index
                      };
            })
        };

        const newItem = {
            maquinaId: selectedMachine?.id,
            prioridade: items.length,
            tempo: convertToSeconds(hours, minutes),
            id: occurenceSelected?.id,
            idOcorrencia: occurenceSelected?.id
        };
        payload.demandas.push(newItem);

        try {
            const { data } = await api.prevDemands(payload);
            toast.update(toastId, {
                render: 'Demandas programadas com sucesso!',
                type: 'success',
                isLoading: false,
                autoClose: 3000
            });

            const lastOcorrenciaIndex = data.map((item) => item.tipo).lastIndexOf('ocorrencia');

            const row = data.map((item, index) => {
                let baseItem = {
                    ...item,
                    id: index,
                    machineData: items[index]?.machineData ? items[index].machineData : items[index]
                };

                if (index === lastOcorrenciaIndex) {
                    return {
                        ...baseItem,
                        idOcorrencia: occurenceSelected?.id,
                        isTemp: true
                    };
                }

                return baseItem;
            });

            setItems(row);
            handleClose();
            setHours(null);
            setMinutes(null);
            setOccurenceSelected(null);
        } catch (error) {
            console.log('🚀 ~ createMachineDemandFunc ~ error:', error);
            toast.update(toastId, {
                render: 'Erro ao programar demandas!',
                type: 'error',
                isLoading: false,
                autoClose: 3000
            });
        }
    }

    return (
        <div>
            <Button variant="contained" onClick={handleOpen}>
                Adicionar ocorrências
            </Button>
            <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                <Box sx={style}>
                    <h3 style={{ textAlign: 'center' }}>Ocorrência</h3>
                    <Divider />
                    <div style={{ display: 'flex', gap: '16px', margin: '16px 0' }}>
                        <Autocomplete
                            fullWidth
                            options={generateOptions(0, 200)}
                            value={hours}
                            onChange={(_, newValue) => setHours(newValue)}
                            renderInput={(params) => <TextField {...params} label="Horas" />}
                        />
                        <Autocomplete
                            options={generateOptions(0, 60)}
                            value={minutes}
                            fullWidth
                            onChange={(_, newValue) => setMinutes(newValue)}
                            renderInput={(params) => <TextField {...params} label="Minutos" />}
                        />
                    </div>

                    <Autocomplete
                        options={occurences}
                        value={occurenceSelected}
                        getOptionLabel={(option) => option.nome}
                        fullWidth
                        onChange={(_, newValue) => setOccurenceSelected(newValue)}
                        renderInput={(params) => <TextField {...params} label="Ocorrências" />}
                        sx={{ marginBottom: 2 }}
                    />
                    <Button
                        variant="contained"
                        disabled={!occurenceSelected || !hours || !minutes}
                        fullWidth
                        onClick={createMachineDemandFunc}
                    >
                        Adicionar
                    </Button>
                </Box>
            </Modal>
        </div>
    );
}
