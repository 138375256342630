/* eslint-disable */
import axios from 'axios';

// railway url 1

const api = axios.create({
    // baseURL: 'https://backendrefrin-production.up.railway.app/'
    // baseURL: 'http://44.201.121.59:8080'
    // baseURL: 'http://localhost:8080'
    baseURL: 'https://refrinapp.mangocliff-3b5c5abb.eastus.azurecontainerapps.io'
});

const clientId = 'bc575e4a-eee9-4b90-8a85-a202e77d332d';
const clientSecret = 'zEc8Q~s65i8RmlQA9YGg6~J4dJyfR2T_KostLc1Z';
//const redirectUri = 'https://backendrefrin-production.up.railway.app/api/OAuth2/get-token';
// const redirectUri = 'https://backrefrinnode-production-3e5b.up.railway.app/api/OAuth2/get-token';
const redirectUri = 'https://refrinapp.mangocliff-3b5c5abb.eastus.azurecontainerapps.io/api/OAuth2/get-token';
const scopes = 'offline_access user.read mail.readwrite';

//teste
//teste
// const api = axios.create({
//     baseURL: 'http://localhost:5001'
//     //baseURL: 'https://rad-ants-production.up.railway.app'
// });

// const api = axios.create({
//     baseURL: 'https://backrefrinnode-production.up.railway.app'
// });

export const useApi = () => ({
    //signin and signout
    signin: async (email, password) => {
        try {
            const response = await api.post('/api/users/login', { email, password });
            return response;
        } catch (error) {
            if (error.response && error.response.data && error.response.data.message) {
                throw new Error(error.response.data.message);
            } else {
                throw new Error(error.message);
            }
        }
    },

    //!  users
    getAllUsers: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/api/users/all', { headers });
        return response;
    },
    getUserById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/users/${id}`, { headers });
        return response;
    },
    getByActor: async (value) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/users/actor/${value}`, { headers });
        return response;
    },
    createUser: async (values) => {
        const response = await api.post('/api/users/register', {
            username: values.username,
            email: values.email,
            sector: values.sector,
            branch: values.branch,
            phone: values.phone,
            role: values.role,
            password: values.password
        });
        return response;
    },
    updateUser: async (values, id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(
            `/api/users/${id}`,
            {
                username: values.username,
                email: values.email,
                phone: values.phone,
                sector: values.sector,
                role: values.role,
                password: values.password
            },
            { headers }
        );

        return response;
    },
    deleteUser: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/users/${id}`, { headers });
        return response;
    },

    //! REQUESTS

    //! MICROSOFT
    loginMicrosoft: async () => {
        const response = await api.get('/api/OAuth2/login');
        return response;
    },

    //! MAIL
    getAllMail: async () => {
        const response = await api.get('/api/mail');
        return response;
    },
    getMailById: async (id) => {
        const response = await api.get(`/api/mail/${id}`);
        return response;
    },
    checkAndSaveMail: async () => {
        try {
            const response = await api.get('/api/graph/fetch-and-save');
            // window.location.reload();
            // console.log(response);
            return response;
        } catch (error) {
            console.error('Erro ao verificar e salvar e-mails: ', error);
            // if (error && error.response && error.response.status === 401) {
            window.open(
                `https://login.microsoftonline.com/common/oauth2/v2.0/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=${scopes}&state=12345`
            );
            // window.location.reload();
            // window.close("http://localhost:3000/main")
            return;
            // }
            throw error;
        }
    },
    getAllArchivedMail: async () => {
        const response = await api.get('/api/mail/archived');
        return response;
    },
    reactivateMail: async (id, from, budgetId, emailCC, subject) => {
        const response = await api.post(`/api/mail/delete/${id}/to/${from}/budgetId/${budgetId}/subject/${subject}`, { emailCC });
        return response;
    },
    deleteMail: async (id) => {
        const response = await api.delete(`/api/mail/delete/${id}`);
        return response;
    },
    deleteAllMailArchived: async () => {
        const response = await api.delete('/api/mail/delete/all/archived');
        return response;
    },
    changeMailStatus: async (id) => {
        const response = await api.put(`/api/mail/${id}`);
        return response;
    },
    resetMailStatus: async (id) => {
        const response = await api.put(`/api/mail/reset/${id}`);
        return response;
    },

    //! BUDGET
    createBudget: async (userId, estimatorId, teste, values) => {
        const response = await api.post(`/api/budget/user/${userId}/estimator/${estimatorId}/create`, {
            subject: teste.subject,
            business: values.business,
            body: teste.body,
            from: teste.from,
            to: teste.to,
            date: teste.date,
            forecast: values.forecast,
            preBudgetCode: values.preBudgetCode,
            budgetReviewCode: values.budgetReviewCode,
            emailCC: values.emailCC,
            budgetCategory: values.budgetCategory,
            budgetObservation: values.budgetObservation
        });

        return response;
    },
    // getAllSellerBudget: async (id) => {
    //     const response = await api.get(`/api/budget/seller/${id}`);
    //     return response;
    // },
    getAllSellerBudget: async (name) => {
        const response = await api.get(`/api/budget/seller/${name}`);
        return response;
    },
    getAllEstimatorBudget: async (name) => {
        const response = await api.get(`/api/budget/estimator/${name}`);
        return response;
    },
    getAllBudgets: async () => {
        const response = await api.get('/api/budget');
        return response;
    },
    getBudgetById: async (id) => {
        const response = await api.get(`/api/budget/${id}`);
        return response;
    },
    changeBudgetStatus: async (id, event) => {
        const response = await api.put(`/api/budget/${id}/event/${event}`);
        return response;
    },

    updateBudget: async (id, values) => {
        console.log(values);
        const response = await api.put(`/api/budget/${id}/update`, {
            business: values.business,
            seller: values.seller,
            budgetEstimator: values.budgetEstimator,
            endForecast: values.endForecast,
            preBudgetCode: values.preBudgetCode,
            budgetReviewCode: values.budgetReviewCode,
            emailCC: values.emailCC,
            budgetCategory: values.budgetCategory,
            budgetObservation: values.budgetObservation
        });
        return response;
    },

    //! BUDGET EVENT

    getAllBudgetEvents: async () => {
        const response = await api.get('/api/budgetEvent');
        return response;
    },

    getBudgetEventById: async (id) => {
        const response = await api.get(`/api/budgetEvent/${id}`);
        return response;
    },

    getStatusBudgetEvent: async (id) => {
        const response = await api.get(`/api/budgetEvent/status/${id}`);
        return response;
    },

    startBudget: async (id) => {
        const response = await api.post(`/api/budgetEvent/${id}/start`);
        return response;
    },
    pauseBudget: async (id, observation) => {
        const response = await api.patch(`/api/budgetEvent/${id}/pause`, { observation: observation });
        return response;
    },

    returnBudget: async (id) => {
        const response = await api.post(`/api/budgetEvent/${id}/return`);
        return response;
    },

    finishBudget: async (id) => {
        const response = await api.post(`/api/budgetEvent/${id}/finish`);
        return response;
    },

    // RH
    // employee
    registerEmployee: async (values) => {
        const response = await api.post('/api/rh/registerEmployee', {
            employeeName: values.employeeName.toUpperCase(),
            sector: parseInt(values.sector),
            branch: parseInt(values.branch)
        });
        return response;
    },
    getAllEmployee: async () => {
        const response = await api.get('/api/rh/allEmployee');
        return response;
    },
    getEmployeeById: async (id) => {
        const response = await api.get(`/api/rh/employee/${id}`);
        return response;
    },
    updateEmployee: async (employeeID, values) => {
        console.log(values);
        const response = await api.put(`/api/rh/employee/${employeeID}`, {
            employeeName: values.employeeName,
            sector: parseInt(values.sector),
            branch: parseInt(values.branch)
        });
        return response;
    },
    deleteEmployee: async (employeeID) => {
        const response = await api.delete(`/api/rh/employee/${employeeID}`);
        return response;
    },
    // sector
    registerSector: async (values) => {
        const response = await api.post('/api/sector/register', {
            sectorName: values.sectorName.toUpperCase()
        });
        return response;
    },
    getAllSector: async () => {
        const response = api.get('/api/sector/all');
        return response;
    },
    getSectorById: async (sectorID) => {
        const response = api.get(`/api/sector/${sectorID}`);
        return response;
    },
    updateSector: async (sectorID, values) => {
        const response = api.put(`/api/sector/${sectorID}`, {
            sectorName: values.sectorName
        });
        return response;
    },
    deleteSector: async (sectorID) => {
        const response = await api.delete(`/api/sector/${sectorID}`);
        return response;
    },

    //branch
    registerBranch: async (values) => {
        const response = await api.post('/api/branch/register', {
            branchName: values.branchName.toUpperCase()
        });
        return response;
    },
    getAllBranch: async () => {
        const response = await api.get('/api/branch/all');
        return response;
    },
    getBranchById: async (branchID) => {
        const response = await api.get(`/api/branch/${branchID}`);
        return response;
    },
    updateBranch: async (branchID, values) => {
        const response = await api.put(`/api/branch/${branchID}`, {
            branchName: values.branchName
        });
        return response;
    },
    deleteBranch: async (branchID) => {
        const response = await api.delete(`/api/branch/${branchID}`);
        return response;
    },
    // reason
    registerReason: async (values) => {
        const response = await api.post('/api/reason/register', {
            reasonName: values.reasonName.toUpperCase()
        });
        return response;
    },
    getAllReason: async () => {
        const response = await api.get('/api/reason/all');
        return response;
    },
    getReasonByID: async (reasonID) => {
        const response = await api.get(`/api/reason/${reasonID}`);
        return response;
    },
    updateReason: async (reasonID, values) => {
        const response = await api.put(`/api/reason/${reasonID}`, {
            reasonName: values.reasonName
        });
        return response;
    },
    deleteReason: async (reasonID) => {
        const response = await api.delete(`/api/reason/${reasonID}`);
        return response;
    },
    // exit
    // registerExit: async (values) => {
    //     const response = await api.post('/api/exit/register', {
    //         employeeName: parseInt(values.employeeName),
    //         exitDate: values.exitDate,
    //         entryTime: values.entryHour,
    //         exitHour: values.exitHour,
    //         returnHour: values.returnHour,
    //         reason: parseInt(values.reason),
    //         observation: values.observation !== '' ? values.observation : null,
    //         returned: values.returned
    //     });
    //     return response;
    // },
    registerExit: async (values) => {
        const response = await api.post('/api/exit/register', values);
        return response;
    },
    getAllExits: async () => {
        const response = await api.get('/api/exit/all');
        return response;
    },
    getExitById: async (id) => {
        const response = await api.get(`/api/exit/${id}`);
        return response;
    },
    updateExit: async (exitID, values) => {
        const response = await api.put(`/api/exit/${exitID}`, values);
        //  {
        //     employeeName: parseInt(values.employeeName),
        //     exitDate: values.exitDate,
        //     entryTime: values.entryHour,
        //     exitHour: values.exitHour,
        //     returnHour: values.returnHour,
        //     reason: parseInt(values.reason),
        //     observation: values.observation,
        //     returned: values.returned,
        //     realReturnHour: values.realReturnHour
        // }

        return response;
    },
    deleteExit: async (exitID) => {
        const response = await api.delete(`/api/exit/${exitID}`);
        return response;
    },
    // ============= EMAIL SUPORTE =============
    sendSuportEmail: async (userId, values) => {
        const response = await api.post(`/api/mail/suporte/userId/${userId}`, {
            cc: values.cc,
            content: values.content,
            subject: values.subject
        });
        return response;
    },
    //pcp
    createCodficator: async (payload) => {
        const response = await api.post('/api/pcp/codificator', payload);
        return response;
    },
    getCodficator: async () => {
        const response = await api.get('/api/pcp/codificator');
        return response;
    },
    updateCodficator: async (id, payload) => {
        const response = await api.put(`/api/pcp/codificator/${id}`, payload);
        return response;
    },
    deleteCodficator: async (id) => {
        const response = await api.delete(`/api/pcp/codificator/${id}`);
        return response;
    }, // distribuição
    createDistributionWorks: async (payload) => {
        const response = await api.post('/api/pcp/distributionWorks', payload);
        return response;
    },
    getAllDistributionWorks: async () => {
        const response = await api.get('/api/pcp/distributionWorks');
        return response;
    },
    getAllDistributionWorks: async () => {
        const response = await api.get('/api/pcp/distributionWorks');
        return response;
    },
    deleteDistribuctionWorks: async (id) => {
        const response = await api.delete(`/api/pcp/distributionWorks/${id}`);
        return response;
    },
    getOpneDistrutionWorks: async (id) => {
        const response = await api.get(`/api/pcp/distributionWorks/${id}`);
        return response;
    },
    EditDistributionWorks: async (idDistribuction, payload) => {
        const response = await api.put(`/api/pcp/distributionWorks/${idDistribuction}`, payload);
        return response;
    },
    //expedição
    getProgNv2: async (gepro) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/expedicao/gepro/${gepro}`, { headers });
        return response;
    },
    getAllExpedition: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/api/expedicao', { headers });
        return response;
    },

    getAtualizationHour: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/api/expedicao/hora/atualizada', { headers });
        return response;
    },
    getACurrentExpedition: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/expedicao/${id}/solicitacao_coleta`, { headers });
        return response;
    },
    createRequestExpedition: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/expedicao/solicitacao_coleta`, payload, {
            headers
        });
        return response;
    },
    sendEmailExpedition: async (payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/expedicao/solicitacao_coleta/send_mail`, payload, {
            headers
        });
        return response;
    },
    getRequestExpeditionId: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/expedicao/${id}/solicitacao_coleta`, {
            headers
        });
        return response;
    },
    getConfirmEx: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/expedicao/${id}/solicitacao_coleta`, {
            headers
        });
        return response;
    },
    confirmRequestExpedition: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/expedicao/${id}/confirmacao_solicitacao_coleta`, payload, {
            headers
        });
        return response;
    },
    sendEmailConfirmRequestExpedition: async (payload) => {
        console.log('🚀 ~ sendEmailConfirmRequestExpedition: ~ payload:', payload);
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/expedicao/confirmacao_solicitacao_coleta/send_mail`, payload, {
            headers
        });
        return response;
    },
    editRequestExpedition: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/expedicao/${id}/solicitacao_coleta`, payload, {
            headers
        });
        return response;
    },
    editConfirmRequestExpedition: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/expedicao/${id}/confirmacao_solicitacao_coleta`, payload, {
            headers
        });
        return response;
    },
    getCollectRequest: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/solicitacao/azul/confirmacao_solicitacao_coleta`, {
            headers
        });
        return response;
    },
    getCollectRequestID: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/solicitacao/azul/confirmacao_solicitacao_coleta/${id}`, {
            headers
        });
        return response;
    },
    getCollectRequestId: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/solicitacao/${id}`, {
            headers
        });
        return response;
    },
    getCollectRequestId2: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        // /api/azul/confirmacao_solicitacao_coleta/${id}
        const response = await api.get(`/api/solicitacao/${id}`, {
            headers
        });
        return response;
    },
    transportRegister: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/transporte/${id}`, payload, {
            headers
        });
        return response;
    },
    getCurentTransport: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/transporte/${id}`, {
            headers
        });
        return response;
    },
    editTransportRegister: async (itemId, solicitacaoColeta, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/transporte/${itemId}/${solicitacaoColeta}`, payload, {
            headers
        });
        return response;
    },
    transportRelease: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/transporte/liberacao/${id}`, payload, {
            headers
        });
        return response;
    },
    releaseEmail: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/transporte/liberacao/email/${id}`, payload, {
            headers
        });
        return response;
    },
    releaseClosure: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/transporte/liberacao/finalizar/${id}`, {
            headers
        });
        return response;
    },
    releaseClosureAV: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/transporteAV/finalizar/${id}`, payload, {
            headers
        });
        return response;
    },
    // transporte avulso
    looseTransport: async (payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/transporteAV/`, payload, {
            headers
        });
        return response;
    },
    looseTransportEmail: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/transporteAV/liberacao/email/${id}`, payload, {
            headers
        });
        return response;
    },
    releaseOfSeparateTransport: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/transporteAV/liberacao/${id}`, payload, {
            headers
        });
        return response;
    }, //agrupamentos nv3
    getGroupsNv3: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/expedicao/nv3/${id}`, {
            headers
        });
        return response;
    }, //novas rotas de expedição
    getNewRequestPage: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/expedicao/status/solicitada`, {
            headers
        });
        return response;
    },
    getNewRequestPageId: async (idExpedition) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/expedicao/${idExpedition}/solicitacoes`, {
            headers
        });
        return response;
    },
    getScheduled: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/expedicao/status/agendada`, {
            headers
        });
        return response;
    },
    getSheduleId: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/expedicao/${id}/confirmacao_solicitacao_coleta`, {
            headers
        });
        return response;
    },
    getConcierge: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/transporte/status/portaria`, {
            headers
        });
        return response;
    },
    getInProgressExpedition: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/transporte/status/carregamento`, {
            headers
        });
        return response;
    },
    postFinishExpedition: async (idRegistro, payload) => {
        console.log('🚀 ~ postFinishExpedition: ~ payload:', payload);
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/transporte/liberacao/finalizar/${idRegistro}`, payload, {
            headers
        });
        return response;
    },
    getFinishedExpedition: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/transporte/status/finalizada`, {
            headers
        });
        return response;
    },
    getFinishedExpeditionAvulso: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/transporteAV/avulso/finalizadas`, {
            headers
        });
        return response;
    },
    getFinishedExpeditionOne: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/transporte/solicitacao/${id}`, {
            headers
        });
        return response;
    },
    firstGetFinishByExpedition: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/transporte/status/finalizada/${id}/solicitacoes`, {
            headers
        });
        return response;
    }, //delete expedição
    firstGetFinishByExpeditionAvulso: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/transporteAV/avulso/finalizado/${id}`, {
            headers
        });
        return response;
    }, //delete expedição
    deleteRequestExpedition: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/expedicao/${id}/solicitacao_coleta`, {
            headers
        });
        return response;
    },
    deleteScheduledExpedition: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/expedicao/${id}/confirmacao_solicitacao_coleta`, {
            headers
        });
        return response;
    },
    deleteRegisterExpedition: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/transporte/${id}`, {
            headers
        });
        return response;
    },
    deleteConciergeExpedition: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/transporte/liberacao/${id}`, {
            headers
        });
        return response;
    },
    deleteFinishExpedition: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/transporte/excluir/${id}`, {
            headers
        });
        return response;
    }, // obter cidade ibge
    getCitiesByIbge: async (uf) => {
        const response = await axios.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);
        return response;
    },
    deleteAvConcierge: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/transporteAV/${id}`, {
            headers
        });
        return response;
    },
    deleteAvProgress: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`api/transporteAV/liberacao/${id}`, {
            headers
        });
        return response;
    },
    deleteAvFinish: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/transporteAV/finalizar/${id}`, {
            headers
        });
        return response;
    },
    getAllExpeditionsWhitStatusFinished: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/transporte/status/finalizada/solicitacoes`, {
            headers
        });
        return response;
    },
    getOneExpeditionWhitStatusFinished: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`api/expedicao/finalizada/${id}`, {
            headers
        });
        return response;
    },
    editExpeditionImages: async (idSolicitacao, payload) => {
        console.log('🚀 ~ postFinishExpedition: ~ payload:', payload);
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/transporte/imgSolicitacao/${idSolicitacao}`, payload, {
            headers
        });
        return response;
    },
    deleteOneImageExpedition: async (idSolicitacao, idImage) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/transporte/imgSolicitacao/${idSolicitacao}/${idImage}`, {
            headers
        });
        return response;
    },
    deleteOneImageExpeditionAV: async (IdRegistro, idImage) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/transporteAV/avulso/img/${IdRegistro}/${idImage}`, {
            headers
        });
        return response;
    },
    editExpeditionImagesAV: async (idRegister, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/transporteAV/avulso/img/${idRegister}`, payload, {
            headers
        });
        return response;
    },
    getAllDistributionWorksAssociated: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/pcp/distributionWorksAssociated/codifications`, {
            headers
        });
        return response;
    },
    getOneDistributionWorksAssociated: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/pcp/distributionWorksAssociated/codifications/${id}`, {
            headers
        });
        return response;
    },
    startCodification: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/pcp/codifications/start/${id}`, payload, {
            headers
        });
        return response;
    },
    finishCodification: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/pcp/codifications/finish/${id}`, payload, {
            headers
        });
        return response;
    },
    returnCodification: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/pcp/codifications/return/${id}`, payload, {
            headers
        });
        return response;
    },
    pauseCodification: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.patch(`/api/pcp/codifications/pause/${id}`, payload, {
            headers
        });
        return response;
    },
    createdCofidification: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/pcp/codifications/create/${id}`, {
            headers
        });
        return response;
    },
    // activity
    getAllActivits: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/pcp/activityEvents`, {
            headers
        });
        return response;
    },
    createNewActivity: async (payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/pcp/activityEvents`, payload, {
            headers
        });
        return response;
    },
    deleteActivity: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/pcp/activityEvents/${id}`, {
            headers
        });
        return response;
    },
    editeActivity: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/pcp/activityEvents/${id}`, payload, {
            headers
        });
        return response;
    },
    alterInHold: async (id, hold) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/pcp/distributionWorks/${id}/in_hold/${hold}`, {
            headers
        });
        return response;
    },
    alterApproval: async (id, hold) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/pcp/distributionWorks/${id}/aprovacao/${hold}`, {
            headers
        });
        return response;
    },

    getAllSales: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get('/api/vendas/all', { headers });
        return response;
    },
    filteredSeller: async (pedido, gepro, filial, cliente, obra, dtInicio, dtFim) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };

        // Construindo a parte de consulta da URL
        let queryString = '';
        if (pedido) queryString += `&pedido=${pedido?.pedido}`;
        if (gepro) queryString += `&gepro=${gepro}`;
        if (filial) queryString += `&filial=${filial}`;
        if (cliente) queryString += `&cliente=${cliente}`;
        if (obra) queryString += `&obra=${obra}`;
        if (dtInicio) queryString += `&dtInicio=${dtInicio}-01`;

        // Adicionando o último dia do mês ao dtFim
        if (dtFim) {
            const yearMonth = dtFim.split('-'); // Dividir a string dtFim para obter ano e mês
            const year = parseInt(yearMonth[0]);
            const month = parseInt(yearMonth[1]);
            const lastDay = new Date(year, month, 0).getDate(); // Obtendo o último dia do mês
            queryString += `&dtFim=${dtFim}-${lastDay}`;
        }

        // Removendo o primeiro '&' se a queryString não estiver vazia
        if (queryString) {
            queryString = '?' + queryString.substring(1);
        }

        const response = await api.get(`/api/vendas/filter${queryString}`, { headers });
        return response;
    },
    getAllRanking: async (dtInicio, dtFim) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/ranking/all?dtInicio=${dtInicio}&dtFim=${dtFim}`, { headers });
        return response;
    },
    getSellerGoals: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/metas/vendedores`, { headers });
        return response;
    },
    createGoals: async (payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/metas`, { metas: payload }, { headers });
        return response;
    },
    getSellerSelected: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/metas/search?agn_in_codigo=${id}`, { headers });
        return response;
    },
    deleteGoals: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/metas/${id}`, { headers });
        return response;
    },
    editableGoals: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/metas/${id}`, payload, { headers });
        return response;
    },
    getCardGoalsView: async (dtInicio, dtFim) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/ranking/vendidoXMeta?dtInicio=${dtInicio}&dtFim=${dtFim}`, { headers });
        return response;
    },
    getLastRanking: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/ranking/lastWinners`, { headers });
        return response;
    },
    finishCodigication: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/pcp/distributionWorksAssociated/codifications/${id}`, { headers });
        return response;
    },
    getReportByDistributionWorks: async (id) => {
        console.log('🚀 ~ getReportByDistributionWorks: ~ id:', id);
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/pcp/allDistributionWorksAssociated/${id}`, { headers });
        return response;
    },
    editDateCodification: async (id, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/pcp/codifications/${id}`, payload, { headers });
        return response;
    },
    getSectorMega: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/setor-mega`, { headers });
        return response;
    },
    insertSectorMega: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/setor/${id}`, {}, { headers });
        return response;
    },
    getSector: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/setor`, { headers });
        return response;
    },
    deleteSector: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/setor/${id}`, { headers });
        return response;
    },
    getWorkCenterMega: async (celInCodgo) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/centro-trabalho-mega/group/${celInCodgo}`, { headers });
        return response;
    },
    createWorkCenter: async (payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/centro-trabalho`, payload, { headers });
        return response;
    },
    getWorkCenterBw: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/centro-trabalho`, { headers });
        return response;
    },
    deleteWorkCenter: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/centro-trabalho/${id}`, { headers });
        return response;
    },
    getWorkCenterLocal: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/centro-trabalho/${id}`, { headers });
        return response;
    },
    createWorkCellLocal: async (data) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.post(`/api/celula-trabalho`, data, { headers });
        return response;
    },
    getLocalWorkCell: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/celula-trabalho`, { headers });
        return response;
    },
    deleteLocalWorkCell: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.delete(`/api/celula-trabalho/${id}`, { headers });
        return response;
    }, // maquinas
    getLocalMachines: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/maquina`, { headers });
        return response;
    },
    createLocalMachine: async (data) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.post(`/api/maquina`, data, { headers });
        return response;
    },
    deleteLocalMachine: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.delete(`/api/maquina/${id}`, { headers });
        return response;
    },
    getCurrentMachine: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.get(`/api/maquina/${id}`, { headers });
        return response;
    },
    updateMachine: async (id, data) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.put(`/api/maquina/${id}`, data, { headers });
        return response;
    },
    createMachineAvailability: async (id, data) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.post(`/api/maq-disponibilidade/${id}`, data, { headers });
        return response;
    },
    getMachineAvailability: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.get(`/api/maq-disponibilidade/${id}`, { headers });
        return response;
    },
    updateMachineAvailability: async (id, data) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.put(`/api/maq-disponibilidade/${id}`, data, { headers });
        return response;
    },
    getMachineParameterMega: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.get(`/api/maq-parametros-second`, { headers });
        return response;
    },
    createMachineParameter: async (machineId, data) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.post(`/api/maq-parametros/${machineId}`, data, { headers });
        return response;
    },
    getMachineParameter: async (machineId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.get(`/api/maq-parametros/${machineId}`, { headers });
        return response;
    },
    deleteMachineParameter: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.delete(`/api/maq-parametros/${id}`, { headers });
        return response;
    },
    updateMachineParameter: async (id, payload, machineId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.put(`/api/maq-parametros/${id}/${machineId}`, payload, { headers });
        return response;
    },
    createMachineFormula: async (machineId, data) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.post(`/api/maq-formulas/${machineId}`, data, { headers });
        return response;
    },
    getMachineFormula: async (machineId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.get(`/api/maq-formulas/${machineId}`, { headers });
        return response;
    },
    updateMachineFormula: async (machineId, formulaId, payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.put(`/api/maq-formulas/${machineId}/${formulaId}`, payload, { headers });
        return response;
    },
    deleteMachineFormula: async (machineId, formulaId) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.delete(`/api/maq-formulas/${machineId}/${formulaId}`, { headers });
        return response;
    },
    getAllDemands: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.get('/api/demandas', { headers });
        return response;
    },
    getDemanmdById: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.get(`/api/demandas/${id}`, { headers });
        return response;
    },
    createMachineDemand: async (payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.post(`/api/demandas/programar`, payload, { headers });
        return response;
    },
    getGroupedDemands: async () => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.get(`api/demandas/grouped/aqui`, { headers });
        return response;
    },
    getByOrdStAlternativo: async (payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.post(`/api/demandas/byOrdStAlternativo/aqui`, payload, { headers });
        return response;
    },
    getDemandsInProgress: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.get(`/api/demandas/emAndamento/${id}`, { headers });
        return response;
    },
    prevDemands: async (payload) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.post(`/api/demandas/preview/aqui`, payload, { headers });
        return response;
    },
    deleteDemands: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.delete(`/api/demandas/${id}`, { headers });
        return response;
    },
    getAllGestaoObrasView: async (pedido, gepro, cliente, obra, username, vendedor, status) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };

        let queryString = '';
        if (pedido) queryString += `&pedido=${pedido}`;
        if (gepro) queryString += `&gepro=${gepro}`;
        if (cliente) queryString += `&cliente=${cliente}`;
        if (obra) queryString += `&obra=${obra}`;
        if (username) queryString += `&username=${username}`;
        if (vendedor) queryString += `&vendedor=${vendedor}`;
        if (status) queryString += `&status_gepro=${status}`;

        if (queryString) {
            queryString = '?' + queryString.substring(1);
        }

        const response = await api.get(`/api/gestao-obras-view${queryString}`, { headers });
        return response;
    },
    exportOpenedDemands: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
            Accept: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        };
        const response = await api.get(`/api/excel/demandas/aberta/${id}`, { headers, responseType: 'arraybuffer' });
        return response;
    },
    createOccurrence: async (name) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/maq-ocorrencia`, name, { headers });
        return response;
    },
    updateOccurrenceName: async (name, id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/maq-ocorrencia/${id}`, name, { headers });
        return response;
    },
    getAllOccurrences: async (name) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.get(`/api/maq-ocorrencia`, { headers });
        return response;
    },
    updateOccurrence: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.put(`/api/demandas/encerrarOcorrencia/${id}`, {}, { headers });
        return response;
    },
    deletarOcorrencia: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
        };
        const response = await api.delete(`/api/demandas/deleteOcorrenciaEmAndamento/${id}`, { headers });
        return response;
    },
    updateDemandaHoraAtual: async (id) => {
        const token = localStorage.getItem('token');
        const headers = {
            Authorization: `Bearer ${token}`
        };
        const response = await api.post(`/api/demandas/atualizarDemandaComHoraAtual/${id}`, {}, { headers }); 
        return response;
    }
});
