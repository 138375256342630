// assets
import {
    IconTool,
    IconVectorBezierArc,
    IconAdjustments,
    IconBriefcase,
    IconGradienter,
    IconBrandDouban,
    IconSchema,
    IconTimelineEventText,
    IconCalendarEvent
} from '@tabler/icons';

import EqualizerIcon from '@mui/icons-material/Equalizer';
const icons = {
    IconTool,
    IconVectorBezierArc,
    IconAdjustments,
    IconBriefcase,
    IconGradienter,
    IconBrandDouban,
    IconTimelineEventText
};

const budget = {
    id: 'pcp',
    title: 'PCP',
    type: 'group',
    children: [
        {
            id: 'dashboardPcp',
            title: 'Dashboard',
            type: 'item',
            url: '/main/graph-pcp',
            icon: EqualizerIcon,
            breadcrumbs: false
        },
        // {
        //     id: 'pcp',
        //     title: 'PCP',
        //     type: 'item',
        //     url: '/main/pcp',
        //     icon: icons.IconTool,
        //     breadcrumbs: false
        // },
        {
            id: 'cadastros-pcp',
            title: 'Cadastros',
            type: 'collapse',
            icon: icons.IconAdjustments,
            children: [
                {
                    id: 'sector-registration',
                    title: 'Setor',
                    type: 'item',
                    url: '/main/pcp/sector-registration',
                    icon: icons.IconVectorBezierArc,
                    breadcrumbs: false
                },
                {
                    id: 'work-center-registration',
                    title: 'Centro de Trabalho',
                    type: 'item',
                    url: '/main/pcp/centro-de-trabalho',
                    icon: icons.IconBriefcase,
                    breadcrumbs: false
                },
                {
                    id: 'celula-registration',
                    title: 'Célula de Trabalho',
                    type: 'item',
                    url: '/main/pcp/celula-de-trabalho',
                    icon: icons.IconGradienter,
                    breadcrumbs: false
                },
                {
                    id: 'maquina-registration',
                    title: 'Maquinas',
                    type: 'item',
                    url: '/main/pcp/cadastro-maquinas',
                    icon: icons.IconBrandDouban,
                    breadcrumbs: false
                },
                {
                    id: 'ocorrencias-registration',
                    title: 'Ocorrências',
                    type: 'item',
                    url: '/main/pcp/ocorrencias',
                    icon: icons.IconTimelineEventText,
                    breadcrumbs: false
                }
            ]
        },
        {
            id: 'demanda',
            title: 'Demandas',
            type: 'item',
            url: '/main/pcp/demandas',
            icon: IconSchema,
            breadcrumbs: false
        },
        {
            id: 'maqscreen',
            title: 'Maquinas',
            type: 'item',
            url: '/main/pcp/maquinas',
            icon: IconTimelineEventText,
            breadcrumbs: false
        }
        // {
        //     id: 'cronograma',
        //     title: 'Cronograma',
        //     type: 'item',
        //     url: '/main/pcp/cronograma-maquinas',
        //     icon: IconCalendarEvent,
        //     breadcrumbs: false
        // }
    ]
};

export default budget;
